:root {
  --color-primary: #125dbe;
  --color-secondary: #d3a517;
  --color-tertiary: #e6f4f1;
  --color-primary-hover: #487ae0;
  --color-font-dark: #202020;
  --color-font-dark-light: #4d4b4b;
}

*, :after, :before {
  box-sizing: border-box;
}

.html {
  font-size: 62.5%;
}

h1 {
  color: var(--color-font-dark);
  font-family: Montserrat, sans-serif;
}

h2, h3, h4, a, p, ul {
  color: var(--color-font-dark);
  font-family: Lato, sans-serif;
}

.grid {
  display: grid;
}

.grid--1x2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid--1x3 {
  grid-template-columns: repeat(3, 1fr);
}

.container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 1rem;
}

.block {
  padding: 2rem 0;
}

.block--primary {
  background: var(--color-primary);
}

.block-footer {
  background: var(--color-primary);
  margin-top: 2rem;
  padding: 1rem;
}

.menu {
  width: 100%;
  z-index: 5;
  background: #fff;
  position: fixed;
  box-shadow: 0 0 10px #0003;
}

.nav {
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
}

.logo {
  width: 30px;
}

.nav__items a {
  color: var(--color-font-dark);
  padding-left: 1rem;
  font-weight: 600;
}

.nav__items a:hover, .nav__logo:hover {
  color: var(--color-font-dark-light);
}

.hero {
  min-height: 500px;
  height: 100vh;
}

.hero .grid {
  height: 100%;
}

.hero .container {
  padding-top: 4rem;
}

.hero__heading {
  margin-bottom: 2rem;
  font-size: 3.5rem;
}

.hero__heading__hello {
  color: var(--color-primary);
  font-size: 1.3rem;
  display: block;
}

.hero__heading__profession {
  color: var(--color-font-dark-light);
  display: block;
}

.hero__text {
  margin-bottom: 2.5rem;
  font-size: 1.3rem;
  line-height: 1.2;
}

.block__start {
  text-align: left;
  align-self: center;
}

.block__end {
  text-align: center;
  align-self: center;
  padding-left: 2rem;
}

.block__image {
  width: 100%;
  max-width: 450px;
  padding-top: 2rem;
}

.btn {
  cursor: pointer;
  border: 0;
  border-radius: 30px;
  padding: .5rem 2rem;
  font-size: 1.2rem;
}

.btn--primary {
  background: var(--color-primary);
  color: #fff;
  font-weight: 600;
}

.btn--primary:hover {
  background: var(--color-primary-hover);
}

.links {
  color: var(--color-secondary);
  font-weight: bold;
}

.heading-section {
  text-align: center;
  color: var(--color-font-dark-light);
  padding-bottom: 2rem;
  font-size: 2.8rem;
}

.text-section {
  text-align: left;
  padding-bottom: 2rem;
  font-size: 1.2rem;
  line-height: 1.2;
}

.about-block {
  padding-bottom: 1.2rem;
  display: flex;
}

.about__text h3 {
  color: var(--color-font-dark);
  padding-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.about__text p {
  text-align: left;
  align-self: center;
  font-size: 1.1rem;
  line-height: 1.2;
}

.about__icon {
  fill: var(--color-primary);
  padding-right: 1.5rem;
}

.icon--big {
  width: 40px;
  height: 40px;
}

.icon {
  width: 30px;
  height: 30px;
}

.icon--primary {
  fill: var(--color-primary);
}

.icon--white {
  fill: #fff;
}

.cards {
  gap: 2rem 1.5rem;
  margin-top: .5rem;
}

.skills__card {
  width: 100%;
  text-align: left;
  border: 2px solid #d5e3e7;
  border-radius: 15px;
  margin-right: 1rem;
  padding: 1rem;
  line-height: 1.4;
}

.skills__card__heading {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.skills__card .icon {
  fill: var(--color-primary);
}

.skills__card h3 {
  color: var(--color-font-light);
  padding-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.bold {
  font-weight: 600;
}

.list-bold {
  margin-top: .5rem;
  font-weight: 600;
}

.work__card {
  width: 100%;
  background-color: #ecf0f3;
  border: 2px solid #d5e3e7;
  border-radius: 15px;
  flex-direction: column;
  padding: .75rem;
  transition: all .2s ease-in;
  display: flex;
  overflow: hidden;
}

.work__card:hover {
  border: 2px solid var(--color-primary);
  opacity: .8;
}

.work__card img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  border-radius: 15px 15px 0 0;
}

.work__card__heading {
  height: auto;
  text-align: right;
  z-index: 2;
  padding: 1rem 0;
}

.work__card__heading h3 {
  text-align: left;
  color: var(--color-font-dark);
  padding-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.2;
}

.work__card__heading p {
  color: var(--color-font-dark-light);
  text-align: left;
  font-size: 1rem;
  line-height: 1.2;
}

.btn-work {
  color: var(--color-primary);
  font-size: 1.1rem;
}

.form {
  flex-direction: column;
  display: flex;
}

input[type="text"], input[type="email"], textarea {
  background: #d3d3d3;
  border: 0;
  border-radius: 30px;
  margin-bottom: 1rem;
  padding: .7rem 1.5rem;
  font-family: Lato, sans-serif;
  font-size: 1.1rem;
}

textarea {
  resize: inherit;
}

textarea:focus, input:focus {
  outline: none;
}

.btn-sent {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.form_image {
  text-align: center;
}

.form_image img {
  max-height: 200px;
}

.submit-group {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#submit-notification {
  color: var(--color-secondary);
  font-weight: 600;
}

.contact__links {
  justify-content: center;
  display: flex;
}

.contact__links .icon {
  fill: #fff;
  margin: 0 .5rem;
}

.footer__copyright {
  text-align: center;
}

.footer__copyright p {
  color: #fff;
}

.footer__copyright span {
  padding: 1rem 0 .5rem;
  font-weight: 600;
  display: block;
}

@media screen and (max-width: 768px) {
  .nav {
    padding: .5rem 1rem;
  }

  .hero {
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
  }

  .hero .container {
    padding-top: 6rem;
  }

  .block__start {
    text-align: center;
  }

  .block__end {
    padding: 1rem 0;
  }

  .grid--1x2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid--1x3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .form_image {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .hero .container {
    padding-top: 4.5rem;
  }

  .grid--1x3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 330px) {
  .hero__heading {
    font-size: 3.2rem;
  }
}

/*# sourceMappingURL=index.8e8a9dbb.css.map */
