:root {
    
    --color-primary: #125dbe;
    --color-secondary: #D3A517;
    --color-tertiary: #E6F4F1;
    --color-primary-hover:#487AE0;
    --color-font-dark: rgb(32, 32, 32);
    --color-font-dark-light: rgb(77, 75, 75);

  }
  
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

/* Default setting */
.html {
    font-size: 62.5%;
}

/* Fonts */
h1 {
    font-family: 'Montserrat', sans-serif;
    color: var(--color-font-dark);
}

h2, h3, h4, a, p, ul {
    font-family: 'Lato', sans-serif;
    color: var(--color-font-dark);
}


/* Grid and containers */
.grid {
    display: grid;
}

.grid--1x2 {
    grid-template-columns: repeat(2,1fr);
}

.grid--1x3 {
    grid-template-columns: repeat(3,1fr);
}

.container {
    max-width: 1024px;
    margin: 0 auto;
    padding: 1rem;
}

/* Blocks */
.block {
    padding: 2rem 0;
}

.block--primary {
    background: var(--color-primary);
}

.block-footer {
    margin-top: 2rem;
    padding: 1rem 1rem;
    background: var(--color-primary);
}

/* Nav menu*/
.menu {
    background: #fff;
    position: fixed;
    width: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 5;
}

.nav {
    display: flex;
    justify-content: space-between;
    padding:0.5rem 1rem;
    align-items: center;  
}

.logo {
    width: 30px;
}

.nav__items a {
    padding-left: 1rem;
    color: var(--color-font-dark);
    font-weight: 600;
}

.nav__items a:hover, .nav__logo:hover  {
    color: var(--color-font-dark-light);
}


/* Hero section  -->DONE*/
.hero {
    min-height: 500px;
    height: 100vh;
}

.hero .grid {
    height: 100%;
}

.hero .container {
    padding-top: 4rem;
}

.hero__heading {
    font-size: 3.5rem;
    margin-bottom: 2rem;
}

.hero__heading__hello {
    font-size: 1.3rem;
    display: block;
    color: var(--color-primary);
}

.hero__heading__profession {
    display: block;
    color: var(--color-font-dark-light);
}

.hero__text {
    font-size: 1.3rem;
    margin-bottom: 2.5rem;
    line-height: 1.2;
}

.block__start {
    text-align: left;
    align-self: center;
}

.block__end {
    text-align: center;
    align-self: center;
    padding-left: 2rem;
}

.block__image {
    width: 100%;
    max-width: 450px;
    padding-top: 2rem;
}


/* Buttons */
.btn {
    border: 0;
    border-radius: 30px;
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.btn--primary {
    background: var(--color-primary);
    color: #fff;
    font-weight: 600;
}

.btn--primary:hover {
    background: var(--color-primary-hover);
}

.links {
    color: var(--color-secondary);
    font-weight: bold;
}


/* Sections heading and text */
.heading-section {
    text-align: center;
    font-size: 2.8rem;
    padding-bottom: 2rem;
    color: var(--color-font-dark-light);
}

.text-section {
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.2;
    padding-bottom: 2rem;
}


/* About me section */
.about-block {
    display: flex;
    padding-bottom: 1.2rem;
}

.about__text h3 {
    font-weight: 600;
    font-size: 1.2rem;
    color: var(--color-font-dark);
    padding-bottom: 0.5rem;
}

.about__text p {
    font-size: 1.1rem;
    text-align: left;
    line-height: 1.2;
    align-self: center;
}

.about__icon {
    padding-right: 1.5rem;
    fill: var(--color-primary);
}

.icon--big {
    width: 40px;
    height: 40px;
}

/* Icons */
.icon {
    width: 30px;
    height: 30px;
  }
  
.icon--primary {
    fill: var(--color-primary);
  }
  
.icon--white {
    fill: #fff;
  }


/* Skills */
.cards {
    gap: 2rem 1.5rem;
    margin-top: 0.5rem;
}

.skills__card {
    border-radius: 15px;
    width: 100%;
    padding: 1rem 1rem;
    text-align: left;
    line-height: 1.4;
    margin-right: 1rem;
    border: solid 2px rgb(213, 227, 231);
}

.skills__card__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.skills__card .icon {
    fill: var(--color-primary);
}

.skills__card h3 {
    font-weight: 600;
    font-size: 1.2rem;
    color: var(--color-font-light);
    padding-bottom: 0.5rem;
}

.bold {
    font-weight: 600;
}

.list-bold {
    font-weight: 600;
    margin-top: .5rem;
}


/* MY WORK */
.work__card {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    background-color: rgb(236, 240, 243);
    border-radius: 15px;
    border: 2px solid rgb(213, 227, 231);
    transition: all 0.2s ease-in;
}

.work__card:hover {
    border: 2px solid var(--color-primary);
    opacity: 0.8;
}

.work__card img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    z-index: 1;
    border-radius: 15px 15px 0px 0px; 
}

.work__card__heading {
    height: auto;
    text-align: right;
    z-index: 2;
    padding: 1rem 0;
}

.work__card__heading h3{
    font-size: 1.2rem;
    padding-bottom: 1rem;
    text-align: left;
    color: var(--color-font-dark);
    line-height: 1.2;
}

.work__card__heading p{
    font-size: 1rem;
    color: var(--color-font-dark-light);
    text-align: left;
    line-height: 1.2;
}

.btn-work {
    color: var(--color-primary);
    font-size: 1.1rem;
}




/* Contact form -->DONE */
.form {
    display: flex;
    flex-direction: column;
}


input[type=text], input[type=email], textarea {
    padding: 0.7rem 1.5rem; 
    font-family: 'Lato', sans-serif;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    border: 0;
    background: lightgray;
    border-radius: 30px;
}
textarea {
    resize: inherit;
}

textarea:focus, input:focus{
    outline: none;
}

.btn-sent {
    max-width: fit-content;
  }

.form_image {
    text-align: center;
}

.form_image img {
    max-height: 200px;
}

.submit-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#submit-notification {
    color: var(--color-secondary);
    font-weight: 600;
}


/* Footer -->DONE*/
.contact__links {
    justify-content: center;
    display: flex;  
}

.contact__links .icon {
    margin: 0 .5rem;
    fill: #fff;
}

.footer__copyright {
    text-align: center;
}

.footer__copyright p {
    color: #fff;
}

.footer__copyright span {
    display: block;
    font-weight: 600;
    padding: 1rem 0 0.5rem 0;
}


/* Tablet version */
@media screen and (max-width: 768px) {

    .nav {
        padding:0.5rem 1rem;  
    }

    .hero {
        height: fit-content;
        text-align: center;
    }
    .hero .container {
        padding-top: 6rem;
    }

    .block__start {
        text-align: center;
    }
    .block__end {
        padding: 1rem 0;
    }

    .grid--1x2 {
        grid-template-columns: repeat(1,1fr);
    }

    .grid--1x3 {
        grid-template-columns: repeat(2,1fr);
    }

    .form_image {
        display: none;
    }

}

/* Mobile version */
@media screen and (max-width: 450px) {
    .hero .container {
        padding-top: 4.5rem;
    }

    .grid--1x3 {
        grid-template-columns: repeat(1,1fr);
    }

}

/* Mobile XSmall version */
@media screen and (max-width: 330px) {
    .hero__heading {
        font-size: 3.2rem;
    }
}